import React from 'react'
import Filters from './components/filters'
import Results from './components/results'

const App = () => {
  return (
    <div className='explore news'>
      <Filters />
      <Results />
    </div>
  )
}

export default App
