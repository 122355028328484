import React, { useContext, useEffect, useState } from 'react'
import appContext from '../context'
import useDebounce from '../hooks/useDebounce'
import search from '../images/icon_search.svg'
import selected from '../images/icon_selected.svg'
import { ReactComponent as DownArrow } from '../images/icon_down_arrow.svg'

const Filters = () => {
  const context = useContext(appContext)

  // stops api call from happening twice on page load
  const [initialized, setInitialized] = useState(false)

  // current filters
  const [filters, setFilters] = useState(context.filters)
  const [query, setQuery] = useState(context.filters.query)

  // accordion logic for mobile filters
  const [accordions, setAccordions] = useState({
    category: false,
  })

  // to reduce api calls, limit the search box to wait 500ms before each call
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    context.getCategories()
  }, [])

  useEffect(() => {
    filterChange('query', debouncedQuery)
  }, [debouncedQuery])

  useEffect(() => {
    if (initialized) {
      context.getResults()
    }
  }, [context.categories, context.filters])

  const categorySelect = (category) => {
    const updatedCategories = [...filters.categories]

    if (updatedCategories.includes(category)) {
      const id = updatedCategories.indexOf(category)
      updatedCategories.splice(id, 1)
    } else {
      updatedCategories.push(category)
    }

    filterChange('categories', updatedCategories)
  }

  // helper function called whenever any filter needs an update
  const filterChange = (key, value) => {
    if (initialized) {
      const updatedFilters = {
        ...filters,
        [key]: value,
      }

      setFilters(updatedFilters)
      context.setFilters(updatedFilters)
    } else {
      setInitialized(true)
    }
  }

  return (
    <div className='explore__filters'>
      <div className='explore__header'>
        <h1>Only in Oakland</h1>

        <div className='explore__search'>
          <input
            name='query'
            type='text'
            placeholder='Search'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <img src={search} />
        </div>
      </div>

      <h5 className='filters-label'>Filter by:</h5>

      <div className='explore__toggles'>
        <label>Category</label>
        <button
          className={`filter-toggle ${accordions.category ? 'open' : ''}`}
          onClick={() =>
            setAccordions({ ...accordions, category: !accordions.category })
          }
        >
          Category <DownArrow />
        </button>

        <div
          className={`explore__toggles__group ${
            accordions.category ? 'open' : ''
          }`}
        >
          {context.categories.map((category) => (
            <button
              key={category}
              className={filters.categories.includes(category) ? 'active' : ''}
              onClick={() => categorySelect(category)}
            >
              {category.replace(/(- news)$/i, '').trim()}
              {filters.categories.includes(category) && <img src={selected} />}
            </button>
          ))}
        </div>
      </div>

      <div className='explore__search'>
        <input
          name='query'
          type='text'
          placeholder='Search'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <img src={search} />
      </div>
    </div>
  )
}

export default Filters
