// Run this example by adding <%= javascript_pack_tag 'news' %> to the head of your layout file,
// like app/views/layouts/application.html.slim.

import React from 'react'
import ReactDOM from 'react-dom'

import News from '../news'

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('news')

  if (root) {
    ReactDOM.render(<News />, root)
  }
})
