import React, { useContext, useEffect, useState } from 'react'
import appContext from '../context'

const Results = () => {
  const context = useContext(appContext)
  const [page, setPage] = useState(context.filters.page)
  const [cards, setCards] = useState([])
  const [external, setExternal] = useState([])

  // sets up cards array
  useEffect(() => {
    if (context.results.posts) {
      setCards(context.results.posts)
    } else {
      setCards([])
    }

    if (context.results.external) {
      setExternal(context.results.external)
    } else {
      setExternal([])
    }
  }, [context.results])

  useEffect(() => {
    setPage(context.filters.page)
  }, [context.filters.page])

  // pagination control
  const changePage = (newPage) => {
    const updatedFilters = {
      ...context.filters,
      page: newPage,
    }

    context.setFilters(updatedFilters)
  }

  return (
    <div className='news__results'>
      <div className='news__results__grid'>
        {cards.map((item) => (
          <div
            key={item.id}
            className={`news-card ${
              item.featured ? 'news-card--featured' : ''
            }`}
          >
            <div className='news-card__image'>
              {item.image ? (
                <img src={item.image} alt={item.alt} />
              ) : (
                <div className='news-card__default-image' />
              )}
            </div>

            <div className='news-card__details'>
              <h3>{item.title}</h3>

              <div className='news-card__info'>
                {item.author && (
                  <div className='news-card__author'>
                    <h6>{item.author}</h6>
                  </div>
                )}

                {item.categories.length > 0 && (
                  <div className='news-card__categories'>
                    <h6>
                      {item.categories.map((category, index) => {
                        if (index > 0) {
                          return `, ${category
                            .replace(/(- news)$/i, '')
                            .trim()}`
                        } else {
                          return category.replace(/(- news)$/i, '').trim()
                        }
                      })}
                    </h6>
                  </div>
                )}

                {item.featured && (
                  <div className='news-card__link'>
                    <a href={item.url} className='button'>
                      More
                    </a>
                  </div>
                )}
              </div>

              {item.excerpt && (
                <div className='news-card__excerpt'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt,
                    }}
                  ></div>
                </div>
              )}

              <div className='news-card__link'>
                <a href={item.url} className='button'>
                  More
                </a>
              </div>
            </div>
          </div>
        ))}
        {cards.length == 0 && (
          <div className='no-results'>
            <p>Sorry, there are no results matching this filter.</p>
          </div>
        )}
        <div className='explore__pagination'>
          {context.results.pageCount > 1 && (
            <>
              <button
                className='page--prev'
                disabled={page === 1}
                onClick={() => changePage(page - 1)}
              >
                <span>previous</span>
              </button>

              {Array(context.results.pageCount)
                .fill()
                .map((_, i) => (
                  <button
                    key={i}
                    className={`page ${page === i + 1 ? 'active' : ''}`}
                    onClick={() => changePage(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}

              <button
                className='page--next'
                disabled={page === context.results.pageCount}
                onClick={() => changePage(page + 1)}
              >
                <span>next</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className='news__external'>
        <h2>In the News</h2>

        {external.map((item) => (
          <div className='external' key={item.id}>
            <a href={item.link} target='_blank'>
              <h3>{item.title}</h3>
            </a>

            {item.publication && <h5>{item.publication}</h5>}

            {item.excerpt && <p>{item.excerpt}</p>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Results
