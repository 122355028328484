import React, { useReducer } from 'react'
import qs from 'qs'

import AppContext from './context'
import { appReducer, SET_RESULTS, SET_CATEGORIES, SET_FILTERS } from './reducer'

import App from './App'

// Context API wrapper for app state management
const News = () => {
  const [state, dispatch] = useReducer(appReducer, {
    categories: [],
    filters: {
      categories: [],
      query: '',
      page: 1,
    },
    results: {
      pageCount: 0,
    },
  })

  const getResults = () => {
    const params = qs.stringify(state.filters, { arrayFormat: 'brackets' })

    fetch(`/only-in-oakland.json?${params}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: SET_RESULTS, results: data.results })
      })
  }

  const getCategories = () => {
    fetch(`/only-in-oakland/categories.json`)
      .then((response) => response.json())
      .then((categories) => {
        dispatch({ type: SET_CATEGORIES, categories })
      })
  }

  const setFilters = (filters) => {
    dispatch({ type: SET_FILTERS, filters })
  }

  return (
    <AppContext.Provider
      value={{
        categories: state.categories,
        results: state.results,
        filters: state.filters,
        getResults,
        getCategories,
        setFilters,
      }}
    >
      <App />
    </AppContext.Provider>
  )
}

export default News
